/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import CheckBox from "../common_input/CheckBox";
import PreviewReferrenceData from "../common_field/PreviewReferrenceData";
import FormulaCard from "../common_field/FormulaCard";
import PreviewReferrenceData2D from "../common_field/PreviewReferrenceData2D";
import Modal from "../common_modal/Modal";
import ConditionalStatementInput from "../common_field/ConditionalStatementInput";
import RamIdSelection from "../common_field/RamIdSelection";

/*REDUX*/
import { editField } from "../../App/actions/layerActions";

/*PICTURE*/

/*FUNCTION*/
import isEqual from "../../App/validation/is-equal";
import dict from "../../Data/dict.json";
import { data_types } from "../../App/validation/data_types";
import {
  polygon_units,
  line_units,
} from "../../App/validation/geometry_to_area_or_length";
import { handle_upload_csv } from "../../App/validation/handle_file";
import { check_formula_validity } from "../../App/validation/formula_validation";
import { generate_array_templates } from "../../App/validation/generate_array_templates";
import { check_conditional_statement_validity } from "../../App/validation/conditional_statement_validation";

/*DATA*/
import referrence_2d_file from "../../Assets/file/[2d-referrence]-[comma-delimited-UTF8].csv";
import referrence_simple_file from "../../Assets/file/[simple-referrence]-[comma-delimited-UTF8].csv";
import SearchCountingCustom from "../editor_search_import/SearchCountingCustom";
import TableFormula from "../common_field/TableFormula";
import DataTypesSelection from "../common_field/DataTypesSelection";

/*CONST*/
const no_need_data_type = ["section"];

class EditField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: {},
      modal_warning: false,
      modal_warning_message: "",
      modal_counting_custom: false,
      poi_rad: 100,
      custom_counting_id: "",
    };
  }

  componentDidMount() {
    const { poi_rad, custom_counting_id } = this?.state?.field;

    if (poi_rad) {
      this.setState({ poi_rad });
    } else {
      this.setState({ poi_rad: 100 });
    }
    if (custom_counting_id) {
      this.setState({ custom_counting_id });
    } else {
      this.setState({ custom_counting_id: "" });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.field, prevState.field)) {
      let field = nextProps?.field;
      const array_templates = field?.array_templates || [];
      const tagArray = array_templates.map((value, idx) => {
        return value.name;
      });
      const tag = tagArray.join("\n");
      field.tag = tag;

      return {
        field: field,
      };
    } else return null;
  }

  toggleModalWarning = (value, message) => {
    const { modal_warning } = this.state;
    this.setState({
      modal_warning: value || !modal_warning,
      modal_warning_message: message || "",
    });
  };

  referColumn = (e) => {
    let field = this.state.field;
    field.reference_key = e.target.value;
    this.setState({ field });
  };

  handleRamAssetNumberFieldKey = (e) => {
    let field = this.state.field;
    field.asset_number_field_key = e.target.value;
    this.setState({ field });
  };

  handleCsvValidation = async (e) => {
    let field = this.state.field;
    const file = await handle_upload_csv(e);
    const headers = file?.headers?.map((field) => field.toUpperCase());
    let is_valid = "invalid";

    if (headers?.includes("NAME") && headers?.includes("VALUE")) {
      is_valid = "valid";
    }

    if (is_valid === "valid") {
      field["reference_list"] = file.data;
      this.setState({
        field,
      });
    }
  };

  //Local change
  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let field = this.state.field;
    if (["tag"].includes(name)) {
      const tagArray = value.split("\n");
      const array_templates = tagArray.map((value, idx) => {
        return { name: value, index: idx };
      });
      field[name] = value;
      field.array_templates = array_templates;
      field.defaultValue = "";
      this.setState({
        field,
      });
    } else {
      field[name] = value;
      this.setState({
        field,
      });
    }
  };

  handleDecimalInput = (e) => {
    if (
      /[0-9]/.test(e.nativeEvent.data) ||
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      let field = this.state.field;
      field["decimal_digits"] = e.target.value;
      this.setState({
        field,
      });
    }
  };

  handleCsvValidation2dReferring = async (e) => {
    let { field } = this.state;
    const { reference_list_2d } = field;
    const file = await handle_upload_csv(e);
    const headers = file?.headers;
    // ?.map((field) => field.toUpperCase());
    let is_valid = "invalid";

    if (headers?.includes("index_name")) {
      is_valid = "valid";
    }
    if (is_valid === "valid") {
      let new_result = reference_list_2d;
      new_result["headers"] = headers;
      new_result["features"] = file.data;
      field["reference_list_2d"] = new_result;

      this.setState({
        field,
      });
    }
  };

  handleRowSelect = (e) => {
    let { field } = this.state;
    const { reference_list_2d } = field;
    let new_ref = reference_list_2d || {};
    new_ref["row_name"] = e.target.value;
    field["reference_list_2d"] = new_ref;
    this.setState({
      field,
    });
  };

  handleColumnSelect = (e) => {
    let { field } = this.state;
    const { reference_list_2d } = field;
    let new_ref = reference_list_2d || {};
    new_ref["column_name"] = e.target.value;
    field["reference_list_2d"] = new_ref;
    this.setState({
      field,
    });
  };

  handleFormula = (value) => {
    let field = this.state.field;
    field.formula = value;
    this.setState({
      field: field,
    });
  };

  handleConditionalStatementList = (value) => {
    let field = this.state.field;
    field["conditional_statement_list"] = value;
    this.setState({
      field: field,
    });
  };

  handleToggle = (e) => {
    const target = e.target;
    const name = target.name;
    let field = this.state.field;
    field[name] = !field[name];
    this.setState({
      field,
    });
  };

  handle_value_template = (new_value) => {
    let field = this.state.field;
    field["value"] = field?.value !== new_value ? new_value : "";
    this.setState({
      field,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { layer_id, geo_layer_list } = this.props.layer;
    let { field, poi_rad, custom_counting_id } = this.state;
    field = {
      ...field,
      conditional_statement_list:
        field?.type === "conditional_statement"
          ? field?.conditional_statement_list
          : [],
      formula: field?.type === "math_operators" ? field?.formula : [],
    };

    const { formula, type, decimal_digits, conditional_statement_list } = field;
    const is_formula_valid =
      check_formula_validity(formula) === "valid" ? true : false;

    const conditional_statement_validation_result =
      check_conditional_statement_validity(conditional_statement_list);

    const geojson_filtered = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id
    )?.geo_layer?.geojson_filtered;

    field = generate_array_templates(field, geojson_filtered);

    field = {
      ...field,
      poi_rad,
      custom_counting_id,
    };

    if (isNaN(decimal_digits) && type === "math_operators") {
      this.toggleModalWarning(true, "Decimal point must be a number");
    } else if (conditional_statement_validation_result?.status === "invalid") {
      this.toggleModalWarning(
        true,
        `Error Logical Statement ${
          conditional_statement_validation_result?.index + 1
        } : ${conditional_statement_validation_result?.message}`
      );
    } else if (
      (type === "math_operators" && is_formula_valid) ||
      (type !== "math_operators" &&
        conditional_statement_validation_result.status === "valid")
    ) {
      const content = {
        geo_layer_id: layer_id,
        field,
      };

      this.props.editField(content);
    }
  };

  set_table_formula = (table_formula) => {
    let { field } = this.state;
    field["table_formula"] = table_formula;
    this.setState({
      field,
    });
  };

  isDataTypeCalculateDimensionAndLayerTypeIsPoint = (dataType, layerType) => {
    if (layerType === "Point" && dataType === "calculate_dimension") {
      return true;
    } else return false;
  };

  handleDataTypeOnClick = (dataType, layerType) => {
    if (
      !this.isDataTypeCalculateDimensionAndLayerTypeIsPoint(dataType, layerType)
    ) {
      let field = this.state.field;
      field.type = dataType;
      this.setState({ field });
    }
  };

  // Counting Custom
  toggle_counting_custom = () => {
    this.setState({
      modal_counting_custom: !this.state.modal_counting_custom,
    });
  };

  set_custom_counting_id = (data) => {
    this.setState({
      custom_counting_id: data,
    });
  };

  render() {
    const language = localStorage?.language || "ina";
    const {
      field,
      modal_warning,
      modal_counting_custom,
      modal_warning_message,
      poi_rad,
      custom_counting_id,
    } = this.state;

    const {
      reference_list,
      reference_key,
      formula,
      reference_list_2d,
      decimal_digits,
      conditional_statement_list,
      table_formula,
    } = field;

    let headers = this.props.headers?.filter((header) => header.uuid);
    const field_clone = Object.assign(field);
    const { loadingProcess, geo_layer_list } = this.props.layer;

    const layer_id_after = this.props.layer.layer_id;
    const layer_details = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id_after
    );
    const type = layer_details.geo_layer?.type;
    const data_type = data_types(language).filter(
      (type) => !no_need_data_type.includes(type.value)
    );
    const array_templates = field_clone?.array_templates || [];

    const status_counting_id =
      custom_counting_id === ""
        ? "Belum Terpilih"
        : `Sudah Terpilih (${custom_counting_id})`;

    // let data_type_filtered;
    // if (type === "Point") {
    //   data_type_filtered = data_type.filter(
    //     (t) => t.value !== "calculate_dimension"
    //   );
    // } else {
    //   data_type_filtered = data_type;
    // }

    // const polygon_units = ["square kilometer", "hectare", "square meter"];
    // const line_units = ["kilometer", "meter", "mile"];\

    const modalWarningContent = modal_warning && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_warning}
        onClose={this.toggleModalWarning}
      >
        <div>
          <h3 className="center_perfect marginBottom_10">Error</h3>
          <div className="center_perfect">{modal_warning_message}</div>
        </div>
      </Modal>
    );

    const modalCountingCustom = modal_counting_custom && (
      <Modal
        modalSize="medium"
        id="modal_select_comparison_data"
        isOpen={modal_counting_custom}
        onClose={this.toggle_counting_custom}
      >
        <div className="box-body">
          <SearchCountingCustom
            toggle_counting_custom={this.toggle_counting_custom}
            set_custom_counting_id={this.set_custom_counting_id}
          />
        </div>
      </Modal>
    );

    let content;
    let buttonContent;
    if (loadingProcess) {
      buttonContent = (
        <button
          className="button background_blue"
          style={{ marginTop: "1rem" }}
        >
          {dict["Saving changes"][language]}
        </button>
      );
    } else {
      buttonContent = (
        <button
          type="submit"
          className="button background_blue"
          onClick={this.handleSubmit.bind(this)}
          style={{ marginTop: "1rem" }}
        >
          {dict["Save changes"][language]}
        </button>
      );
    }
    content = (
      <main>
        <section className="text_bold">
          {dict["Edit attribute"][language]}
        </section>
        <div style={{ width: "100%", overflowY: "auto" }}>
          <section className="container_flat" style={{ marginBottom: "15px" }}>
            <div className="text_bold">{dict["Name"][language]}</div>
            <input
              type="text"
              name="name"
              id="name"
              value={field_clone.name}
              onChange={this.handleChange}
            />
            <CheckBox
              text="isRequired"
              title={dict["Required to fill by respondent"][language]}
              value={field_clone.isRequired}
              handle={this.handleToggle}
            />
          </section>
          <div className="container_flat" style={{ marginBottom: "15px" }}>
            <div className="text_bold">{dict["Description"][language]}</div>
            <textarea
              rows="4"
              type="text"
              name="description"
              id="description"
              placeholder={dict["Describe this field here"][language]}
              value={field_clone.description}
              onChange={this.handleChange}
            />
          </div>
          <section className="container_flat" style={{ marginBottom: "15px" }}>
            <div className="text_bold">{dict["Type data"][language]}</div>
            <div id="data_type_container">
              <DataTypesSelection
                field={field}
                geo_layer_type={type}
                language={language}
                data_type={data_type}
                handleDataTypeOnClick={this.handleDataTypeOnClick}
                isDataTypeCalculateDimensionAndLayerTypeIsPoint={
                  this.isDataTypeCalculateDimensionAndLayerTypeIsPoint
                }
              />
            </div>
            {/* {data_type.map(({ name, value, icon }) => {
              let class_button = "button_inactive";
              if (value === field.type) {
                class_button = "button_active";
              } else if (
                this.isDataTypeCalculateDimensionAndLayerTypeIsPoint(
                  value,
                  type
                )
              ) {
                class_button = "button_disable";
              }
              return (
                <div
                  onClick={() => {
                    this.handleDataTypeOnClick(value, type);
                  }}
                  key={value}
                  className={`button_box ${class_button}`}
                >
                  <div className="center_perfect w_full h_full">
                    <div>
                      <img
                        src={icon}
                        alt={name}
                        className="w_20 h_20 margin_auto"
                      />
                      <div className="font_11 marginTop_5">{name}</div>
                    </div>
                  </div>
                </div>
              );
            })} */}
          </section>

          {field_clone.type === "range" && (
            <section className="container_flat marginTop_15">
              <div className="text_bold">{dict["Minimum value"][language]}</div>
              <input
                type="number"
                name="min"
                id="min"
                placeholder="Minimum value"
                value={field_clone.min ? field_clone.min : ""}
                onChange={this.handleChange}
              />
              <div className="text_bold">{dict["Maximum value"][language]}</div>
              <input
                type="number"
                name="max"
                id="max"
                placeholder="Maximum value"
                value={field_clone.max ? field_clone.max : ""}
                onChange={this.handleChange}
              />
              <div className="text_bold">{dict["Step value"][language]}</div>
              <input
                type="number"
                name="step"
                id="step"
                placeholder="Step value"
                value={field_clone.step ? field_clone.step : ""}
                onChange={this.handleChange}
              />
            </section>
          )}
          {/*  */}

          {field_clone.type === "calculate_dimension" && (
            <section
              className="container_flat"
              style={{ marginBottom: "15px" }}
            >
              <div className="text_bold">{"Unit"}</div>
              <select
                id="unit"
                name="unit"
                value={field_clone.unit}
                onChange={this.handleChange.bind(this)}
              >
                <option value="">Select</option>
                {(type === "MultiLineString" || type === "LineString") &&
                  line_units.map((element, index) => (
                    <option key={index} value={element.key}>
                      {element.name}
                    </option>
                  ))}
                {(type === "Polygon" || type === "MultiPolygon") &&
                  polygon_units.map((element, index) => (
                    <option key={index} value={element.key}>
                      {element.name}
                    </option>
                  ))}
              </select>
            </section>
          )}

          {field_clone.type === "simple_referring" && (
            <main className="container_flat marginBottom_15">
              <label className="font_18 w_full">Simple Referring</label>
              <div className="flex gap_10 paddingTop_10">
                <a
                  className="bg_blue pointer rounded_5 font_14 h_35 w_200 text_white hover_darkBlue"
                  href={referrence_simple_file}
                >
                  <div className="center_perfect w_full h_full">
                    Template CSV Download
                  </div>
                </a>

                <label className="inline">
                  <div
                    className={`center_perfect pointer rounded_5 font_14 h_35 text_white bg_blue hover_darkBlue`}
                  >
                    {"Upload CSV"}
                  </div>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept=".csv*"
                    onChange={this.handleCsvValidation}
                  />
                </label>
                {reference_list?.length > 0 && (
                  <PreviewReferrenceData
                    data={reference_list}
                    field={field}
                    layer_id={layer_id_after}
                  />
                )}
              </div>
              <div className="paddingTop_10">
                <label htmlFor="refer_to_column" className="font_18">
                  {dict["Refer to"][language]}
                </label>
                <select
                  id="reference_list"
                  name="reference_list"
                  value={reference_key}
                  onChange={this.referColumn}
                >
                  <option key="" value="">
                    Select Column
                  </option>
                  {headers
                    ?.filter((header) => header.field !== field_clone.key)
                    .map((head) => {
                      return (
                        <option key={head.field} value={head.field}>
                          {head.headerName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </main>
          )}

          {field_clone.type === "table_formula" && (
            <main className="container_flat paddingTop_10 marginBottom_15">
              <TableFormula
                language={language}
                table_formula={table_formula}
                set_table_formula={this.set_table_formula}
              />
            </main>
          )}

          {field_clone.type === "ram" && (
            <section
              className="container_flat"
              style={{ marginBottom: "15px" }}
            >
              <RamIdSelection
                headers={headers}
                field={field_clone}
                handleRamAssetNumberFieldKey={this.handleRamAssetNumberFieldKey}
              />
            </section>
          )}

          {field_clone.type === "2d_referring" && (
            <main className="container_flat">
              <div>
                <label className="font_18 w_full">2D Referring</label>
                <div className="flex gap_10 paddingTop_10">
                  <a
                    className="bg_blue pointer rounded_5 font_14 h_35 w_200 text_white hover_darkBlue"
                    href={referrence_2d_file}
                  >
                    <div className="center_perfect w_full h_full">
                      Template CSV Download
                    </div>
                  </a>

                  <label className="inline">
                    <div
                      className={`center_perfect pointer rounded_5 font_14 h_35 text_white bg_blue hover_darkBlue`}
                    >
                      {dict["Upload CSV"][language]}
                    </div>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept=".csv*"
                      onChange={this.handleCsvValidation2dReferring}
                    />
                  </label>
                  {reference_list_2d?.features?.length > 0 && (
                    <PreviewReferrenceData2D data={reference_list_2d} />
                  )}
                </div>
                <div className="two-container">
                  <div className="paddingTop_10 grid_child">
                    <label className="font_14 w_full">
                      {
                        dict[
                          "Select column name from main table that will refer as a row in secondary table"
                        ][language]
                      }
                    </label>
                    <select
                      id="reference_list"
                      name="reference_list"
                      value={reference_list_2d?.["row_name"]}
                      onChange={this.handleRowSelect}
                    >
                      <option key="" value="">
                        {dict["Select column"][language]}
                      </option>
                      {headers?.map((header) => {
                        return (
                          <option key={header.field} value={header.field}>
                            {header.headerName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="paddingTop_10 grid_child">
                    <label className="font_14 w_full">
                      {
                        dict[
                          "Select column name from main table that will refer as a column in secondary table"
                        ][language]
                      }
                    </label>
                    <select
                      id="reference_list"
                      name="reference_list"
                      value={reference_list_2d?.["column_name"]}
                      onChange={this.handleColumnSelect}
                    >
                      <option key="" value="">
                        {dict["Select column"][language]}
                      </option>
                      {headers?.map((header) => {
                        return (
                          <option key={header.field} value={header.field}>
                            {header.headerName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </main>
          )}

          {field_clone.type === "math_operators" && (
            <main className="container_flat paddingTop_10 marginBottom_15">
              <FormulaCard
                from={this.props.from}
                formula={formula}
                headers={headers}
                handleFormula={this.handleFormula}
              />
              <div>
                <label className="font_18 w_full">Decimal</label>
                <div>
                  <label className="w_full">Insert a decimal point</label>
                  <div>
                    <label>Places:</label>
                    <input
                      type="number"
                      required={true}
                      onChange={this.handleDecimalInput}
                      value={decimal_digits}
                      min={0}
                    />
                  </div>
                </div>
              </div>
            </main>
          )}

          {field_clone.type === "conditional_statement" && (
            <main className="container_flat paddingTop_10 marginBottom_15">
              <ConditionalStatementInput
                language={language}
                headers={headers}
                handleConditionalStatementList={
                  this.handleConditionalStatementList
                }
                conditional_statement_list={conditional_statement_list}
              />
            </main>
          )}

          {/* <main className="container_flat paddingTop_10 marginBottom_15">
            
          </main> */}
          {field_clone.type === "counting_custom" && (
            <section
              className="container_flat"
              style={{ marginBottom: "15px", marginTop: "15px" }}
            >
              <div className="text_bold">
                {dict["Custom Counting"][language]}
              </div>
              <p>Pilih layer untuk counting</p>
              <p>Status : {status_counting_id}</p>

              <button
                className="button background_blue"
                onClick={() => this.toggle_counting_custom()}
              >
                Buka Pencarian
              </button>
              <br />
              <br />
              <p>Atur radius counting</p>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <input
                  type="number"
                  style={{ width: "100px", textAlign: "center" }}
                  value={poi_rad}
                  onChange={(e) => this.setState({ poi_rad: e.target.value })}
                />
                <h2>meter</h2>
              </div>
            </section>
          )}

          <section
            className="container_flat"
            style={{ marginBottom: "15px", marginTop: "15px" }}
          >
            <div className="text_bold">{dict["Value template"][language]}</div>
            <CheckBox
              text="isAllowOtherAnswer"
              title={dict["Allow other answers"][language]}
              value={field.isAllowOtherAnswer}
              handle={this.handleToggle}
            />
            <textarea
              type="text"
              name="tag"
              id="tag"
              autoComplete="off"
              value={field_clone.tag ? field_clone.tag : ""}
              onChange={this.handleChange.bind(this)}
              rows="5"
            />
            <div className="input_note">
              {
                dict[
                  "Fill in if you want to provide several value options for attribute input (in MAPID FORM). Separate between values ​​with next values ​​with enter."
                ][language]
              }
            </div>
            <div className="flex gap_5 marginTop_10">
              {array_templates.map((content, idx) => {
                const is_active = field_clone.value === content.name;
                return (
                  <main key={idx}>
                    <div
                      onClick={() => this.handle_value_template(content.name)}
                      style={{
                        border: "1px solid #b4b6b8",
                      }}
                      className={`h_20 w_fit padding_5 rounded_5 pointer noselect ${
                        is_active ? "button_active" : "button_inactive"
                      }`}
                    >
                      {content.name}
                    </div>
                    <br />
                  </main>
                );
              })}
            </div>
          </section>
          <section className="container_flat" style={{ marginBottom: "15px" }}>
            <CheckBox
              text="isHide"
              title={dict["Hide attribute"][language]}
              value={field_clone.isHide}
              handle={this.handleToggle.bind(this)}
            />
            <CheckBox
              text="isHighlight"
              title={dict["Hightlight attribute"][language]}
              value={field_clone.isHighlight}
              handle={this.handleToggle.bind(this)}
            />
          </section>
        </div>
        <section
          style={{
            paddingTop: 30,
            width: "100%",
            position: "absolute",
            bottom: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {buttonContent}
        </section>
      </main>
    );
    return (
      <main>
        {content}
        {modalWarningContent}
        {modalCountingCustom}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, { editField })(EditField);
