import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function RamIdSelection(props) {
  const { headers, field, handleRamAssetNumberFieldKey } = props;

  const handleChange = (e) => {
    handleRamAssetNumberFieldKey(e);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        Pilih Kolom Nomor Aset
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        // value={age}
        label="Pilih Kolom Nomor Aset"
        onChange={handleChange}
      >
        {headers
          .filter((item) => item.headerName !== field.name)
          .map((item, index) => {
            const { headerName, field } = item;
            return (
              <MenuItem key={index} value={field}>
                {headerName}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
