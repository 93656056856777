import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

// const SERVER_URL = "http://localhost:4000";
// const SERVER_URL = "https://server.mapid.io";
// const SERVER_URL = `http://${window.location.host}:4000`;
const SERVER_URL = geoServerBaseUrl;

export const setLoading = (itemLoading) => {
  return {
    type: "AUTH_LOADING_PROCESS",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_AUTH_LOADING",
  };
};

/*POSTY*/

//1. Create Group
export const addGroup = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("create_group"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/groups_new/create",
      body,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "ADD_GROUP",
      payload: res.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//2. Edit Group
export const editGroup = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_group"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/groups_new/edit", body, config);
    dispatch(clearLoading());
    dispatch({
      type: "EDIT_GROUP",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

/**
 * deleteMember
 * @endpoint : /groups_new/delete_member
 * @methode : POST
 * @body : member_id, member_name, group_id,
 * @params : -
 */
export const deleteMember = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("delete_member"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/groups_new/delete_member", body, config);
    dispatch(clearLoading());
    dispatch({
      type: "DELETE_MEMBER",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

/**
 * editMember
 * @endpoint : /groups_new/edit_member
 * @methode : POST
 * @body : group_id, member_id, member_user_id, status
 * @body (optional): folder_id
 * @params : -
 */
export const editMember = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_member"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/groups_new/edit_member", body, config);
    dispatch(clearLoading());

    dispatch({
      type: "EDIT_MEMBER",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const editMemberProject = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_member"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/groups_new/edit_member",
      body,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "EDIT_PROJECT",
      payload: res.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

/**
 * addMember
 * @endpoint : /groups_new/add_member
 * @methode : POST
 * @body : group_id, member_id
 * @params : -
 */
export const addMember = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("add_member"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/groups_new/add_member",
      body,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "ADD_MEMBER",
      payload: res.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//6. Join Group
export const joinGroup = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("join_group"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/groups_new/join_group", body, config);
    dispatch(clearLoading());
    dispatch({
      type: "JOIN_GROUP",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

//7. Confirm member
export const confirmMember = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("confirm_member"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/groups_new/confirm_member", body, config);
    dispatch(clearLoading());
    dispatch({
      type: "CONFIRM_MEMBER",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};

/*GET METHODE*/

//1. Get Group List By Member _id
export const getGroups = () => async (dispatch) => {
  try {
    if (localStorage.token_mapid) {
      dispatch(setLoading("get_by_member"));
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL + `/groups_new/get_by_member`,
        config
      );
      dispatch({
        type: "GET_GROUPS",
        payload: res.data,
      });
      dispatch(clearLoading());
    }
  } catch (error) {
    dispatch(clearLoading());
  }
};

//2. Get Group List By Project
export const getGroupsProject = (project_link) => async (dispatch) => {
  try {
    if (localStorage.token_mapid) {
      dispatch(setLoading("get_by_project"));
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL + `/groups_new/get_by_project/${project_link}`,
        config
      );
      dispatch({
        type: "GET_GROUPS_PROJECT",
        payload: res.data,
      });
      dispatch(clearLoading());
    }
  } catch (error) {
    dispatch(clearLoading());
  }
};

//3. Get Group Detail
export const getGroup = (group_id) => async (dispatch) => {
  try {
    dispatch(setLoading("get_by_member"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(SERVER_URL + `/groups_new/${group_id}`, config);
    dispatch({
      type: "GET_GROUP",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//4. Delete Group
export const deleteGroup = (content) => async (dispatch) => {
  try {
    dispatch(setLoading("delete_group"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(SERVER_URL + `/groups_new/${content.group_id}`, config);
    dispatch({
      type: "DELETE_GROUP",
      payload: content.group_id,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//5. Search Users
export const searchUser = (search) => async (dispatch) => {
  try {
    if (search) {
      dispatch(setLoading("search_user"));
      const config = {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      };
      const res = await axios.get(
        SERVER_URL + `/groups_new/search_user/${search}`,
        config
      );
      dispatch({
        type: "SEARCH_USERS",
        payload: res.data,
      });
      dispatch(clearLoading());
    }
  } catch (error) {
    dispatch(clearLoading());
  }
};

// BARU
export const edit_active = (body) => async (dispatch) => {
  /*
body: {
group_id: _id dari group yang akan diganti active_status nya
user_id: _id dari user yang akan diganti, ambil dari members[element].user 
BUKAN members[element]._id

active_status: "active" || "not_active"  //defaultnya "active"
}

*/

  try {
    await axios.post(SERVER_URL + "/groups/edit_active", body);
    dispatch({
      type: "edit_active",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};
