import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CheckCircleOutlineRounded } from "@material-ui/icons";

import { useState } from "react";
import { getAssetNumber } from "../../App/actions/kaiActions";
import { useDispatch } from "react-redux";
import Modal from "../common_modal/Modal";
import { Typography } from "@mui/material";

export default function RamIntegration(props) {
  const { params, column } = props;
  const [showModal, setShowModal] = useState(false);
  const [getAssetResult, setGetAssetResult] = useState({});
  const { asset_number_field_key } = column;
  const dispatch = useDispatch();
  const rowAsset = params.row[asset_number_field_key];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#bee2fc",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const toggle_get_asset = async () => {
    if (showModal === false) {
      const result = await dispatch(getAssetNumber(rowAsset));
      setGetAssetResult(result);
      if (result) {
        setShowModal(!showModal);
      }
    } else {
      setShowModal(!showModal);
    }
  };

  const toggle_close_modal = () => {
    setShowModal(false);
  };

  return (
    <>
      <button className="button background_blue" onClick={toggle_get_asset}>
        Rincian
      </button>
      {showModal && (
        <Modal
          modalSize="small"
          isOpen={showModal}
          onClose={toggle_close_modal}
        >
          <div className="box-body" id="box-body">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>Rincian Aset</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                <CheckCircleOutlineRounded color="primary" />
                <p style={{ color: "#757575" }}>Integrasi RAM Berhasil</p>
              </div>
            </div>
            <br />
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="customized table">
                <TableBody>
                  {getAssetResult &&
                    typeof getAssetResult === "object" &&
                    Object.keys(getAssetResult).map((key, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            fontSize={12}
                          >
                            {key}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {getAssetResult[key]}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Modal>
      )}
    </>
  );
}
