/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import centroid from "@turf/centroid";
import ReactToPrint from "react-to-print";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

/*COMPONENT*/
import RANGKING_CHART from "./RANKING_CHART";
import MARKDOWN_RENDERER from "../sini_v2_full/MARKDOWN_RENDERER";
import ProgressBar from "../common_spinner/ProgressBar";
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  get_ai_hbu_summary,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
} from "../../App/actions/ai_excel";
import { push_ai_sheet_chat } from "../../App/actions/push_ai_sheet_chat";
import { generate_ai_hbu_summary } from "../../App/actions/generate_ai_hbu_summary";

/*PICTURE*/

/*FUNCTION*/
import { convertExcelDataToHandsontableFormat } from "../../App/validation/convert_data";
import uuid from "../../App/validation/uuid";
import MAP_EXCEL from "./MAP_EXCEL";
import PARENT_SINI from "../sini_v2/PARENT_SINI";
import sort_array from "../../App/validation/sort_array";

/*DATA*/

/*CONST*/

class EXCEL_HBU extends Component {
  constructor(props) {
    super(props);
    this.component_ref = React.createRef();
    this.state = {
      // sheet_array: [],
      user_message: "",
      modal_re_hbu: false,
      user_message_hbu: "",
      is_chat_ai_show: false,
    };

  }

  componentDidMount() {
    //basic
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.props.get_ai_hbu_summary(params);
    this.props.get_ai_sheet_chat_list(params);
    //advance
    const { feature_object_selected } = this.props.layer;
    const type = feature_object_selected?.geometry?.type;

    let long = 0;
    let lat = 0;

    if (type === "Point") {
      long = feature_object_selected?.geometry?.coordinates?.[0];
      lat = feature_object_selected?.geometry?.coordinates?.[1];
    } else {
      const geojson = {
        type: "FeatureCollection",
        features: [feature_object_selected],
      };
      const feature_center = centroid(geojson);
      long = feature_center?.geometry?.coordinates?.[0];
      lat = feature_center?.geometry?.coordinates?.[1];
    }

    const params_sini = {
      geo_layer_id,
      feature_key,
      field_key,
      long,
      lat,
      request_id: uuid(),
      is_from_excel: true,
      sini_data_mode: "sini_general",
      show_survey_1: true,
    };
    this.props.get_ai_sini_sheet(params_sini);
  }

  toggle_re_hbu = () => {
    this.setState({
      modal_re_hbu: !this.state.modal_re_hbu,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  on_generate_ai_hbu_summary = () => {
    const { user_message_hbu } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    let sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );

    if (sini_object?.["people_spending"]) {
      delete sini_object["people_spending"]
    }

    const { worksheet } = this.props;
    const sheet_array = worksheet.map((sheet) => {
      const selectedSheet = sheet?.metadata?.id_ori;
      const { data } = convertExcelDataToHandsontableFormat({
        sheet,
        selectedSheet,
        rawData: worksheet,
      });
      return data;
    });

    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_hbu,
    };

    // console.log("body",body)

    this.props.generate_ai_hbu_summary(body);
    this.setState({
      user_message_hbu: "",
      modal_re_hbu: false,
    });
  };

  reset_ai_hbu_summary = () => {
    // const { sheet_array } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );

    const { worksheet } = this.props;
    const sheet_array = worksheet.map((sheet) => {
      const selectedSheet = sheet?.metadata?.id_ori;
      const { data } = convertExcelDataToHandsontableFormat({
        sheet,
        selectedSheet,
        rawData: worksheet,
      });
      return data;
    });

    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_hbu: "",
    };
    this.props.generate_ai_hbu_summary(body);
    this.setState({
      user_message_hbu: "",
      modal_re_hbu: false,
    });
  };

  on_regenerate_ai_hbu_summary = () => {
    const { user_message_hbu, sheet_array } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      // ai_hbu_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      // worksheet: ai_hbu_summary_object,
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_hbu,
    };
    this.props.generate_ai_hbu_summary(body);
    this.setState({
      user_message_hbu: "",
      modal_re_hbu: false,
    });
  };

  on_push_ai_sheet_chat = () => {
    const { user_message } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      ai_hbu_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      ai_hbu_summary_object,
      user_message,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
    };
    this.props.push_ai_sheet_chat(body);
    this.setState({
      user_message: "",
    });
  };

  toggle_chat_ai = () => {
    this.setState({
      is_chat_ai_show: !this.state?.is_chat_ai_show,
    });
  };

  render() {
    //local storage

    //local state
    const { user_message, modal_re_hbu, user_message_hbu, is_chat_ai_show } =
      this.state;

    //global props
    const { is_mobile } = this.props.properties;
    const { loading_item_array } = this.props.loading_reducer;
    const {
      total_step,
      current_step,
      current_topic_loading,
      ai_hbu_summary_object,
      ai_sheet_chat_list,
      ai_sini_sheet_object,
    } = this.props.sini;

    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const properties = sini_object?.demography_one?.properties || {};
    const { DESA, KECAMATAN, KABKOT, PROVINSI } = properties;

    //content
    const modal_re_hbu_content = modal_re_hbu && (
      <Modal
        modalSize="small"
        isOpen={modal_re_hbu}
        onClose={this.toggle_re_hbu}
      >
        <div className="box-body" id="box-body">
          <textarea
            className="margin_bottom"
            rows={6}
            placeholder="Masukkan pesan untuk pembaruan summary"
            value={user_message_hbu}
            name="user_message_hbu"
            onChange={this.on_change}
          />
          <br />
          <button
            className="button_big background_black margin_right margin_bottom"
            onClick={this.on_regenerate_ai_hbu_summary}
          >
            Perbarui Summary
          </button>
          <button
            className="button_big background_black"
            onClick={this.reset_ai_hbu_summary}
          >
            Reset
          </button>
        </div>
      </Modal>
    );

    const summary_result = ai_hbu_summary_object?.summary_result;
    let summary_content;
    let summary_sini = "";
    // let rekomendasi_transportasi = ""
    // let supply_demand = "";
    if (!summary_result) {
      summary_content = (
        <section className="text_center container_light outline_transparent background_grey_light">
          <p className="text_bold margin_bottom">
            Belum dilakukan perangkuman HBU
          </p>
          <br />
          {loading_item_array.includes("generate_ai_hbu_summary") ? (
            <button className="button_big background_grey">
              AI SEDANG MELAKUKAN SUMMARY...
            </button>
          ) : !ai_sini_sheet_object?.request_id ? (
            <button className="button_big background_grey">Wait</button>
          ) : (
            <button
              className="button_big background_black"
              onClick={this.on_generate_ai_hbu_summary}
            >
              Generate HBU Summary
            </button>
          )}
          <br />
        </section>
      );
    } else {
      const hbu_best_alternative = summary_result?.hbu_best_alternative || "";
      const hbu_best_alternative_reason =
        summary_result?.hbu_best_alternative_reason || "";
      const hbu_best_alternative_notes =
        summary_result?.hbu_best_alternative_notes || "";
      const hbu_alternative_array = summary_result?.hbu_alternative_array || [];

      const {
        data_object_hbu = "",
        perhitungan_sesuai_tata_ruang_tanah_kosong = "",
        perhitungan_sesuai_tata_ruang_bangunan = "",
        uji_maximum_productivity = "",
        uji_fisik_hbu_tanah_kosong = "",
        uji_fisik_hbu_bangunan_existing = "",
        alasan_dipilihnya_ranking_1 = "",
      } = summary_result || {};
      summary_sini = summary_result?.summary_sini || "";
      // rekomendasi_transportasi = summary_result?.rekomendasi_transportasi || ""
      // supply_demand = summary_result?.supply_demand || "";

      summary_content = (
        <section
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            overflowY: "auto",
            height: "100%",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1 className="margin_bottom">HBU SUMMARY</h1>
          <section className="margin_bottom">
            {loading_item_array.includes("generate_ai_hbu_summary") ? (
              <button className="button_big background_grey">
                AI SEDANG MELAKUKAN SUMMARY...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button className="button_big background_grey">WAIT</button>
            ) : (
              <button
                className="button_big background_black"
                onClick={this.toggle_re_hbu}
              >
                Perbarui Ringkasan HBU dengan Prompt
              </button>
            )}
          </section>

          <aside className="container_column">
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="margin_bottom">
                <label>Nama object: </label>
                <p className="text_bold">{data_object_hbu}</p>
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Provinsi</label>
                <p className="text_bold">{PROVINSI}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kabupaten atau Kota</label>
                <p className="text_bold">{KABKOT}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kecamatan</label>
                <p className="text_bold">{KECAMATAN}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Desa atau Kelurahan</label>
                <p className="text_bold">{DESA}</p>
              </div>
            </div>

            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Rangkuman kalkulasi HBU
              </h1>
              <RANGKING_CHART hbu_alternative_array={hbu_alternative_array} />

              <div className="container_light background_white">
                <h1 className="text_bold margin_bottom">
                  Alternatif HBU terbaik
                </h1>
                <p className="badge background_black">{hbu_best_alternative}</p>
              </div>
            </div>

            <div className="child_tile span_3 container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">Uji Legal HBU</h1>

              <h1 className="text_bold margin_bottom">
                Perhitungan Sesuai Tata Ruang (Tanah Kosong)
              </h1>
              <p>{perhitungan_sesuai_tata_ruang_tanah_kosong}</p>
              <br />
              <h1 className="text_bold margin_bottom">
                Perhitungan Sesuai Tata Ruang (Bangunan)
              </h1>
              <p>{perhitungan_sesuai_tata_ruang_bangunan}</p>
            </div>
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Maximum Productivity
              </h1>
              <p>{uji_maximum_productivity}</p>
            </div>
            {/* </section> */}

            {/* <section className="border"> */}
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Fisik HBU (Tanah Kosong)
              </h1>
              <p>{uji_fisik_hbu_tanah_kosong}</p>
            </div>
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Uji Fisik HBU Bangunan (Existing)
              </h1>
              <p>{uji_fisik_hbu_bangunan_existing}</p>
            </div>
            {/* </section> */}
          </aside>

          <div
            style={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "1fr",
            }}
          >
            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold">
                Rangkuman finansial setiap alternatif
              </h1>

              <table className="table scroll_auto">
                <thead>
                  <tr className="font_10">
                    <th></th>
                    <th>CAPEX</th>
                    <th>NPV</th>
                    <th>IRR</th>
                    <th>PI</th>
                    <th>PP</th>
                    <th>DR</th>
                    {/* <th>Raw score</th> */}
                    <th>Result score</th>
                    <th>Keterangan</th>
                    <th>Indikator Kelayakan</th>
                    <th>Rangking</th>
                  </tr>
                </thead>
                <tbody>
                  {sort_array(hbu_alternative_array, "result_score", false).map((item, idx) => {
                    const {
                      name,
                      npv,
                      irr,
                      pi,
                      capex,
                      payback_periode_year,
                      // raw_score,
                      result_score,
                      reasoning_behind_the_result_score,
                      discount_rate,
                      indikator_kelayakan
                      // rangking,
                    } = item;

                    const to_id_format = (num_val) => {
                      return new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      })?.format(Number(num_val));
                    };

                    return (
                      <tr key={idx} className="font_10">
                        <td>{name}</td>
                        <td>{to_id_format(capex)}</td>
                        <td>{to_id_format(npv)}</td>
                        <td>{to_id_format(irr?.toFixed(2))}</td>
                        <td>{to_id_format(pi)}</td>
                        <td>
                          {to_id_format(payback_periode_year?.toFixed(1))}
                        </td>
                        <td>{discount_rate}</td>
                        {/* <td>{to_id_format(raw_score)}</td> */}
                        <td>{to_id_format(result_score)}</td>
                        <td>{reasoning_behind_the_result_score}</td>
                        <td>{indikator_kelayakan}</td>
                        <td>{to_id_format(idx + 1)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="h_full">
                <br />
                <h1 className="text_bold margin_bottom">
                  Alasan Dipilihnya Alternatif Ranking 1
                </h1>
                <p className="text_medium">{alasan_dipilihnya_ranking_1}</p>
                <br />
                <h1 className="text_bold margin_bottom">
                  Alasan Pemilihan Alternatif
                </h1>
                <p className="text_medium">
                  <MARKDOWN_RENDERER markdownContent={hbu_best_alternative_reason} />
                </p>
                <br />
                <h1 className="text_bold margin_bottom">Catatan</h1>
                <p className="text_medium">{hbu_best_alternative_notes}</p>
              </div>
            </div>
          </div>
        </section>
      );
    }

    const chat_content = (
      <main className="parent_section_full container_light outline_transparent background_grey_light">
        <section className="section_1">
          <p className="badge background_black">
            AI CHAT ({!ai_sini_sheet_object?.request_id ? "Load..." : "Ready"})
          </p>
        </section>
        <section
          className="section_2"
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "10px",
              maxHeight: "calc( 80vh - 150px )",
            }}
          >
            <div>
              {total_step !== current_step && (
                <ProgressBar
                  current_number={current_step}
                  total_number={total_step}
                  name={current_topic_loading}
                />
              )}
            </div>
            {ai_sheet_chat_list.map((chat, idx) => {
              let final_content;
              if (chat.role === "assistant" && chat.content) {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div className="container_light outline_transparent margin_bottom background_dark_blue">
                      <MARKDOWN_RENDERER markdownContent={chat.content} />
                    </div>
                  </section>
                );
              } else {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div
                      className="container_light outline_transparent margin_bottom max_width_300 background_white"
                      style={{ right: 0, whiteSpace: "pre-wrap" }}
                    >
                      {chat.content}
                    </div>
                  </section>
                );
              }
              return final_content;
            })}
          </div>
          <div
            id="chat_dialog"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <textarea
              name="user_message"
              value={user_message}
              onChange={this.on_change}
              rows={6}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            {loading_item_array.includes("push_ai_sheet_chat") ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Sending...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Wait
              </button>
            ) : (
              <button
                className="button background_blue"
                style={{
                  alignSelf: "flex-start",
                }}
                onClick={this.on_push_ai_sheet_chat}
              >
                Chat
              </button>
            )}
          </div>
        </section>
      </main>
    );
    let content;
    if (!is_mobile) {
      content = (
        <main
          ref={el => (this.componentRef = el)}
          style={{ display: "flex", gap: "10px", height: "calc(100vh - 75px)" }}
        >
          <section
            style={{
              width: "50%",
            }}
          >
            {summary_content}
          </section>

          <section
            style={{
              display: "flex",
              height: "100%",
              width: "50%",
              gap: "15px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div style={{ height: "50%" }}>
                <MAP_EXCEL />
              </div>
              <div
                className="child_tile container_light background_grey_light outline_transparent"
                style={{ height: "50%" }}
              >
                {summary_result && <div style={{ height: "100%" }}>
                  {/* <div className="summary_sini" dangerouslySetInnerHTML={{ __html: summary_sini }} /> */}
                  <div style={{ maxHeight: "45vh", overflowY: "auto" }}>
                    <h1 className="bold">Summary SINI</h1>
                    <hr />
                    <MARKDOWN_RENDERER markdownContent={summary_sini} />
                    {/* <br />
                    <h1 className="bold">Rekomendasi Transportasi</h1>
                    <hr />
                    <p>{rekomendasi_transportasi}</p> */}
                  </div>
                </div>}
              </div>
            </div>
            <div
              style={{
                width: "300px",
                overflowY: "auto",
              }}
            >
              <PARENT_SINI from="ai_hbu" />
            </div>
          </section>

          <section
            style={{
              visibility: is_chat_ai_show ? "visible" : "hidden",
              width: is_chat_ai_show ? "300px" : "0px",
              flexShrink: 0,
              flexGrow: 0,
              height: "100%",
            }}
          >
            {chat_content}
          </section>
        </main>
      );
    } else {
      content = <main>Mode mobile not supported yet</main>;
    }

    const button_ai_chat = (
      <button
        className="floating_button circle_button center_perfect"
        onClick={this.toggle_chat_ai}
      >
        <div className="flex flex_col">
          <span className="w_full flex justify_center">
            <QuestionAnswerIcon />
          </span>
          <label className="font_10 pointer" >Chat AI</label>
        </div>
      </button>
    );

    return (
      <main>
        {modal_re_hbu_content}
        <ReactToPrint
          trigger={() => (
            <button className="absolute left top button marginTop_30 background_blue">Print PDF</button>
          )}
          content={() => this.componentRef}

        />
        {content}
        {button_ai_chat}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  generate_ai_hbu_summary,
  get_ai_hbu_summary,
  push_ai_sheet_chat,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
})(EXCEL_HBU);
