/*eslint-disable*/

// personal components
import Handsontable from "handsontable";
import {
  Header,
  Slider,
  Textarea,
  Checklist,
  Selection,
  Image,
  PhoneNumber,
  Time,
  Document,
  CalculateDimension,
  SimpleReferring,
  MathOperators,
  ConditionalStatement,
  Text,
  TABLE_NESTED_CELL,
  DateCell,
  CountingCustomEditor,
  HeaderUneditable,
  RamIntegration,
} from "../../Components/custom_cell";
import ExcelTable from "../../Components/excel/ExcelTable";
import { clients_that_use_laporan_pdf, ip_dev } from "./all_about_client";
import {
  calculateExcelFormula,
  getAllSelectedValue,
  parseReference,
} from "./calculateExcelFormula";
import {
  data_types_that_must_be_calculate_before_render,
  calculate_by_data_type,
} from "./data_types";
import { is_valid_url } from "./is_valid_url";
import is_not_empty from "./is_not_empty";
// import uuid from "./uuid";
const language = localStorage?.language ? localStorage?.language : "ina";
// import moment from "moment/moment";

/**
 * Function ini dipake buat Tabel utama yang menggunakan library MUI Datagrid. Tujuannya buat menyesuaikan template custom cell dengan tipe data kolomnya.
 */
export const convertFieldDataTypesToHead = function ({
  column,
  layer_id,
  handleEditCellChange,
  editProperties,
  sortedFeatures,
  is_from_first_gen_table,
}) {
  switch (column.type) {
    case "sheet":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          const { field, row } = params;
          return (
            <ExcelTable
              column_type={column.type}
              feature_key={row.key}
              field_key={field}
              feature={row}
            />
          );
        },
      };
    case "sheet_hbu":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          const { field, row } = params;
          return (
            <ExcelTable
              column_type={column.type}
              feature_key={row.key}
              field_key={field}
              feature={row}
            />
          );
        },
      };
    case "sheet_appraisal":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          const { field, row } = params;
          return (
            <ExcelTable
              column_type={column.type}
              feature_key={row.key}
              field_key={field}
              feature={row}
            />
          );
        },
      };
    case "counting_custom":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <CountingCustomEditor
              params={params}
              row={params.row}
              column={column}
            />
          );
        },
      };
    case "calculate_dimension":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <CalculateDimension column={column} params={params} />;
        },
      };
    case "table":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (e) => {
          const feature =
            sortedFeatures?.find(
              (feature) => feature?.properties?.key === e?.row?.key
            ) || e.row;

          return (
            <TABLE_NESTED_CELL
              layer_id={layer_id}
              row={feature}
              column={column}
              is_from_first_gen_table={is_from_first_gen_table}
            />
          );
        },
      };
    case "text":
      return {
        type: "string",
        width: 200,
        editable: true,
        renderCell: ({ value }) => <Text value={value} />,
      };
    case "number":
      return {
        type: "number",
        width: 200,
        renderCell: (params) => {
          const value = params?.value;
          let formattedValue = 0;
          if (!is_not_empty(value) && isNaN(value)) {
            formattedValue = 0;
          } else {
            formattedValue = new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(Number(value));
          }
          return <div className="text_right w_full">{formattedValue}</div>;
        },
      };
    case "textarea":
      return {
        type: "string",
        width: 500,
        editable: false,
        renderCell: (params) => {
          return (
            <Textarea
              params={params}
              editProperties={editProperties}
              layer_id={layer_id}
              handleEditCellChange={handleEditCellChange}
            />
          );
        },
      };
    case "range":
      return {
        type: "number",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <Slider
              params={params}
              editProperties={editProperties}
              layer_id={layer_id}
              handleEditCellChange={handleEditCellChange}
            />
          );
        },
      };
    case "phone_number":
      return {
        type: "string",
        width: 250,
        editable: false,
        renderCell: (params) => {
          return (
            <PhoneNumber
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
              // toggleModalNotification={toggleModalNotification}
            />
          );
        },
      };
    case "currency":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          const hasError = !Number.isInteger(parseInt(params.props.value));
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return (
            <div className="text_right w_full">
              {!isNaN(params?.value)
                ? new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(Number(params?.value))
                : ""}
            </div>
          );
        },
      };
    case "table_formula":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          const hasError = !Number.isInteger(parseInt(params.props.value));
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return (
            <div className="text_right w_full">
              {!isNaN(params?.value)
                ? new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(Number(params?.value))
                : ""}
            </div>
          );
        },
      };
    case "arithmetic":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          const hasError = !Number.isInteger(parseInt(params.props.value));
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return (
            <div className="text_right w_full">
              {!isNaN(params?.value)
                ? new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(Number(params?.value))
                : ""}
            </div>
          );
        },
      };
    case "year":
      return {
        type: "number",
        width: 200,
        preProcessEditCellProps: (params) => {
          let hasError = !Number.isInteger(parseInt(params.props.value));
          if (params.props.value < 0) {
            hasError = true;
          }
          return { ...params.props, error: hasError };
        },
        renderCell: (params) => {
          return <section>{params.value}</section>;
        },
      };
    case "date":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <DateCell
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "time":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <Time
              column={column}
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "selection":
      return {
        type: "singleSelect",
        width: 200,
        valueOptions: column.array_templates.map((option) => option.name),
        editable: false,
        renderCell: (params) => {
          return (
            <Selection
              column={column}
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "radio_button":
      return {
        type: "string",
        width: 200,
        valueOptions: column.array_templates.map((option) => option.name),
        editable: false,
        renderCell: (params) => {
          return (
            <Selection
              column={column}
              params={params}
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "checklist":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <Checklist
              column={column}
              params={params}
              packThePropertiesDataToUseApiEdit={
                packThePropertiesDataToUseApiEdit
              }
              handleEditCellChange={handleEditCellChange}
              editProperties={editProperties}
              layer_id={layer_id}
            />
          );
        },
      };
    case "image":
      return {
        type: "string",
        isImage: true,
        width: 250,
        editable: false,
        renderCell: (params) => {
          return (
            <Image
              id={"ciaat"}
              params={params}
              language={language}
              layer_id={layer_id}
            />
          );
        },
      };
    case "document":
      return {
        type: "string",
        isDoc: true,
        width: 250,
        editable: false,
        renderCell: (params) => {
          return (
            <Document params={params} language={language} layer_id={layer_id} />
          );
        },
      };
    case "link":
      return {
        type: "string",
        width: 200,
        editable: true,
        renderCell: (params) => {
          let value_url = params.value;
          let href = params.value;
          // let style = "text_black";
          if (is_valid_url(params.value)) {
            if (params.value?.includes("https://")) {
              // style = "text_blue underline";
            } else if (params.value?.includes("http://")) {
              // style = "text_orange underline";
            } else {
              href = `https://${params.value}`;
              // style = "text_blue underline";
            }
          }

          return (
            params.value && (
              <a
                className="url"
                rel="noopener noreferrer"
                target="_blank"
                href={href}
              >
                {value_url}
              </a>
            )
          );
        },
      };
    case "auto_address":
      return {
        type: "string",
        width: 300,
        renderCell: ({ value }) => (
          <span style={{ height: "100%", overflowY: "auto" }}>{value}</span>
        ),
      };
    case "auto_address_poi":
      return {
        type: "string",
        width: 300,
        renderCell: ({ value }) => (
          <span style={{ height: "100%", overflowY: "auto" }}>{value}</span>
        ),
      };
    case "section":
      return {
        type: "string",
        isSection: true,
      };
    case "simple_referring":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return (
            <SimpleReferring
              // language={language}
              // geometry={params.row.geometry}
              // column={column}
              params={params}
            />
          );
        },
      };
    case "2d_referring":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <SimpleReferring params={params} />;
        },
      };
    case "math_operators":
      return {
        type: "number",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <MathOperators params={params} />;
        },
      };
    case "conditional_statement":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <ConditionalStatement params={params} />;
        },
      };
    case "nilai_pembanding":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return new Intl.NumberFormat("id-ID", {
            style: "decimal",
          })?.format(params?.value);
        },
      };
    case "jarak_apraisal":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return new Intl.NumberFormat("id-ID", {
            style: "decimal",
          })?.format(params?.value);
        },
      };
    case "ram":
      return {
        type: "string",
        width: 200,
        editable: false,
        renderCell: (params) => {
          return <RamIntegration params={params} column={column} />;
        },
      };
    default:
      return {
        type: "string",
        width: 200,
      };
  }
};

const push = (headers, new_header) => {
  if (new_header) {
    headers.push(new_header);
  }
};

const unshift = (headers, new_header) => {
  if (new_header) {
    headers.unshift(new_header);
  }
};

/**
 * Keterangan:
 *
 * Function ini digunakan untuk mengubah fields menjadi headers. Kenapa perlu diubah ke header? Jawabannya adalah supaya dapat dibaca oleh Mui Datagrid (tabel dengan library MUI)
 *
 */
export const convertFieldsToHeaders = function (variables, methods, columns) {
  const {
    fields,
    layer_id,
    sortedFeatures,
    geo_layer,
    is_from_first_gen_table,
    is_editable_field,
  } = variables;
  const {
    handleEditCellChange,
    toggleEditField,
    toggleDeleteField,
    // toggleModalNotification,
    editProperties,
  } = methods;
  const {
    deleteColumn,
    newColumn,
    column_surveyor,
    column_status,
    column_pembanding,
    column_value_pembanding,
    column_download_laporan_pdf,
  } = columns;

  let headers = fields.map((column) => {
    if (column.name) {
      let result = {
        ...column,
        field: column.key,
        headerName: column.name,
        editable: true,
        sortable: true,
        description: column.name,
        renderHeader: (params) => {
          if (is_editable_field) {
            return (
              <Header
                params={params}
                column={column}
                toggleEditField={toggleEditField}
                toggleDeleteField={toggleDeleteField}
              />
            );
          } else {
            return <HeaderUneditable column={column} />;
          }
        },
      };

      const newAttributes = convertFieldDataTypesToHead({
        column,
        layer_id,
        handleEditCellChange,
        editProperties,
        // toggleModalNotification,
        sortedFeatures,
        is_from_first_gen_table,
        // column_surveyor
      });

      result = {
        ...result,
        ...newAttributes,
      };

      delete result.key;
      delete result.name;

      return result;
    } else {
      return column;
    }
  });

  if (geo_layer?.is_survey) {
    if (geo_layer?.layer_data_list?.[0]?.name !== undefined) {
      unshift(headers, column_value_pembanding);
      unshift(headers, column_pembanding);
    }

    /* fitur client: kolom khusus untuk download laporan pdf
     yang seleksi berdasarkan domain */
    const domain = window.location.hostname;
    if (
      clients_that_use_laporan_pdf.includes(domain) ||
      domain?.includes(ip_dev)
    ) {
      unshift(headers, column_download_laporan_pdf);
    }

    unshift(headers, column_surveyor);
    unshift(headers, column_status);
  }

  unshift(headers, deleteColumn);
  push(headers, newColumn);
  headers = headers.filter((head) => !head?.isSection);

  return headers;
};

/**
 * Keterangan:
 *
 * Function ini kurang lebih sama dengan convertFieldsToHeaders yaitu untuk mengubah fields menjadi headers, tapi yang ini KHUSUS untuk NESTED TABLE. Kenapa perlu diubah ke header? Jawabannya adalah supaya dapat dibaca oleh Mui Datagrid (tabel dengan library MUI)
 *
 */
export const convertFieldsToHeadersForSecondGenOrMore = function (
  variables,
  methods
  // columns
) {
  const { fields, layer_id, child, sortedFeatures } = variables;
  const {
    // handlePreviewImage,
    // toggleImagePreview,
    handleEditCellChange,
    toggleEditField,
    toggleDeleteField,
    // toggleModalNotification,
    // toggle_table,
    editProperties,
  } = methods;
  // const {
  //   deleteColumn,
  //   newColumn,
  //   column_surveyor,
  //   column_status,
  //   column_pembanding,
  //   column_value_pembanding,
  //   column_download_laporan_pdf,
  // } = columns;

  let headers = fields?.map((column) => {
    if (column.name) {
      let result = {
        ...column,
        field: column.key,
        headerName: column.name,
        editable: true,
        sortable: false,
        description: column.name,
        renderHeader: (params) => {
          return (
            <Header
              params={params}
              column={column}
              toggleEditField={toggleEditField}
              toggleDeleteField={toggleDeleteField}
            />
          );
        },
      };

      const newAttributes = convertFieldDataTypesToHead({
        column,
        layer_id,
        handleEditCellChange,
        editProperties,
        sortedFeatures,
      });

      result = {
        ...result,
        ...newAttributes,
      };

      delete result.key;
      delete result.name;

      return result;
    } else {
      return column;
    }
  });

  // if (geo_layer?.geo_layer?.is_survey) {
  //   if (geo_layer?.geo_layer?.layer_data_list?.[0]?.name !== undefined) {
  //     headers.unshift(column_download_laporan_pdf);
  //     headers.unshift(column_value_pembanding);
  //     headers.unshift(column_pembanding);
  //   }
  //   // headers.unshift(column_download_laporan_pdf);
  //   headers.unshift(column_surveyor);
  //   headers.unshift(column_status);
  // }

  // headers.unshift(deleteColumn);
  // headers.push(newColumn);
  headers = headers?.filter((head) => !head?.isSection);

  return headers;
};

/**
 * Keterangan:
 *
 * Function ini untuk mengubah attribute properties menjadi data row yang dapat dibaca oleh Mui Datagrid (tabel dengan library MUI). Di dalam function ini sudah termasuk dengan kalkulasi data dengan formula.
 *
 */

export const convertPropertiesToDataList = function ({
  features,
  fields,
  layer_pembanding,
  feature_parent,
  feature_appraisal,
  source,
  capex_value,
  nested_attributes,
}) {
  const fieldKeysThatNeedToCalculateBeforeRender = fields.filter((field) =>
    data_types_that_must_be_calculate_before_render.includes(field.type)
  );

  const data_list = features.map((feature, idx) => {
    const index = feature?.parent_uuid ? idx : feature.idx;

    const calculated_feature = calculate_by_data_type({
      feature,
      fields: fieldKeysThatNeedToCalculateBeforeRender,
      layer_pembanding,
      feature_parent,
      feature_appraisal,
      source,
      capex_value,
    });
    const properties = calculated_feature?.properties || {};
    let body = {
      ...properties,
      id: index,
      key: feature.key || feature.child_uuid,
      formStatus: feature.formStatus,
      user: feature.user,
      geometry: feature.geometry,
      data_pembanding_list: feature?.data_pembanding_list || [],
      counting_custom_array: feature?.counting_custom_array || [],
    };
    if (nested_attributes) {
      body["nested_attributes"] = nested_attributes;
    }
    return body;
  });
  return data_list;
};

/**
 * Keterangan:
 *
 * Function ini hanya untuk kalkulasi data features dengan tipe data formula (math operator, conditional statement, dll), ingat TANPA KONVERSI ke MUI Datagrid.
 *
 * Kemudian ketika function ini dipanggil, FEATURES YANG DIINPUTKAN KE DALAM PARAMETER SUDAH IKUT BERUBAH TANPA HARUS MERETURN,
 *
 * tapi function ini tetap melakaukan return. Hanya untuk berjaga-jaga untuk kasus tertentu.
 *
 */
export const calculateWithoutConvert = function (
  {
    features,
    fields,
    source,
    layer_pembanding,
    feature_parent,
    feature_appraisal,
    capex_value,
  } // features, // fields, // source, // layer_pembanding, // feature_parent, // feature_appraisal
) {
  const fieldKeysThatNeedToCalculateBeforeRender = fields.filter((field) =>
    data_types_that_must_be_calculate_before_render.includes(field.type)
  );
  const data_list = features.map((feature, index) => {
    const calculated_feature = calculate_by_data_type({
      feature,
      fields: fieldKeysThatNeedToCalculateBeforeRender,
      source,
      layer_pembanding,
      feature_parent,
      feature_appraisal,
      capex_value,
    });
    return calculated_feature;
  });
  return data_list;
};

/**
 * Fitur ini sama halnya dengan convertFieldsToHeaders yaitu ubah fields ke headers biar bisa dipake di libary Mui Datagrid.
 * Bedanya fitur ini cuma nambahin atribut field (key) dan headerName (column name) aja.
 * Tujuan dibuatnya fitur ini adalah buat di form editor, karena beberapa komponen di form editor sama seperti di map editor, jadi beberapa butuh headers dan bukan fields.
 *
 * Semoga kedepannya function ini bisa dihapus aja karena nggak terlalu dibutuhin.
 */
export const simpleConvertFieldsToHeaders = function (fields) {
  let headers = fields.map((column) => {
    if (column.name) {
      let result = {
        ...column,
        field: column.key,
        headerName: column.name,
      };
      return result;
    } else {
      return column;
    }
  });
  return headers;
};

/**
 * Fitur ini cuma buat ngepacking data feature supaya langsung bisa dimasukin ke body API editProperties & editPropertiesV2
 */
export const packThePropertiesDataToUseApiEdit = function (layer_id, feature) {
  return {
    geo_layer_id: layer_id,
    feature_key: feature.row.key,
    properties_key: feature.field,
    properties_value: feature.value,
  };
};

/**
 * Fitur ini cuma buat ngepacking data feature supaya langsung bisa dimasukin ke body API edit_child_array
 */
export const packThePropertiesDataToUseApiEditNested = function (
  layer_id,
  params
) {
  const value = !isNaN(params?.value) ? +params?.value : params?.value;

  return {
    geo_layer_id: layer_id,
    feature_key: params?.row?.nested_attributes?.feature_key,
    child_uuid: params?.row?.key,
    properties_key: params?.field,
    properties_value: value,
  };
};

/*
  function untuk generate kolom dari A-Z, AA-ZZ, AAA-ZZZ, dst...
*/
export const generateColumns = (lastColumn) => {
  const columns = [];
  const startCharCode = "A".charCodeAt(0);
  const getColumnName = (index) => {
    let name = "";
    while (index >= 0) {
      name = String.fromCharCode((index % 26) + startCharCode) + name;
      index = Math.floor(index / 26) - 1;
    }
    return name;
  };

  let endIndex = 0;
  if (lastColumn) {
    let charIndex = 0;
    for (let i = lastColumn.length - 1; i >= 0; i--) {
      endIndex +=
        (lastColumn.charCodeAt(i) - startCharCode + 1) *
        Math.pow(26, charIndex++);
    }
  }

  for (let i = 0; i <= endIndex + 5; i++) {
    const columnName = getColumnName(i);
    columns.push({ data: columnName, title: columnName });
  }

  return columns;
};

/* 
  function ini untuk konversi ke excel ke library handsontable 
*/
export const convertExcelDataToHandsontableFormat = ({
  sheet,
  rawData,
  selectedSheet,
}) => {
  const data = [];
  const columnSet = new Set();

  // Parsing each cell from the rawData object
  if (Array.isArray(sheet?.value)) {
    for (const cellData of sheet?.value) {
      if (cellData.p && cellData.p.match(/^[A-Z]+\d+$/)) {
        // Check for column-row pattern in cell position
        const colLetter = cellData.p.match(/[A-Z]+/)[0];
        const rowNumber = parseInt(cellData.p.match(/\d+/)[0], 10);

        // Add the column letter to the set to determine existing columns
        columnSet.add(colLetter);

        // Ensure data has enough rows
        while (data.length < rowNumber) {
          data.push({});
        }

        // Populate cell values into the data array at the correct row position
        if (cellData.f) {
          const result = calculateExcelFormula({
            sheetName: selectedSheet,
            cell: cellData,
            rawData,
          });
          if (isNaN(result) || result?.[0] === "0") {
            data[rowNumber - 1][colLetter] = result;
          } else {
            data[rowNumber - 1][colLetter] = new Intl.NumberFormat(
              "en-US"
            ).format(Number(result));
          }
        } else {
          if (isNaN(cellData?.v) || cellData?.v?.[0] === "0") {
            if (cellData?.v === undefined || cellData?.v === "" || cellData?.v === null) {
              data[rowNumber - 1][colLetter] = null;
            } else {
              data[rowNumber - 1][colLetter] = cellData.v;
            }
          } else {
            data[rowNumber - 1][colLetter] = new Intl.NumberFormat(
              "en-US"
            ).format(Number(cellData.v));
          }
        }
      }
    }
  }

  // Sort columns alphabetically
  const sortedColumns = Array.from(columnSet).sort();

  // Create column definitions in the correct order for Handsontable
  const original_columns =
    sortedColumns.map((col) => ({ data: col, title: col })) || [];
  const letter = original_columns?.at(-1)?.data;
  let columns = [];
  if (letter) {
    columns = generateColumns(letter || "A");
  }

  return { data, columns };
};

/*
  buat styling handsontable
*/
export const setStyleHandsonTable = ({
  row,
  col,
  data,
  sheet,
  worksheet,
  selectedSheet,
}) => {
  // const { worksheet, selectedSheet } = this.state
  let cellProperties = {};
  const cellRef = Handsontable.helper.spreadsheetColumnLabel(col);
  let cellData = data?.[row]?.[cellRef];
  const cellFromXlsx = sheet?.value?.find(
    (item) => item?.p === `${cellRef}${row + 1}`
  );
  const style = cellFromXlsx?.d;

  if (style) {
    if (cellFromXlsx?.o) {
      if (typeof cellFromXlsx?.o === "string") {
        const { sheetName, cellRef } = parseReference(
          cellFromXlsx?.o?.replaceAll("$", "")
        );
        const options = getAllSelectedValue({
          rangeCode: cellRef,
          sheetName: sheetName || selectedSheet,
          rawData: worksheet,
        });
        cellProperties.type = "dropdown";
        cellProperties.source = options;
      } else if (Array.isArray(cellFromXlsx?.o)) {
        cellProperties.type = "dropdown";
        cellProperties.source = cellFromXlsx?.o;
      }
    }

    if (style?.decimal && is_not_empty(cellData) && !isNaN(cellData)) {
      const decimalMatch = style?.decimal.match(/0\.0+|#\.#+/);
      let decimals = 0;

      if (decimalMatch) {
        decimals = (decimalMatch[0].split(".")[1] || "").length;
      }

      // Format value sesuai jumlah desimal di numFmt
      cellData = parseFloat(cellData).toFixed(decimals);
    }

    // Tambahkan gaya
    cellProperties.renderer = function (instance, td) {
      td.innerText = is_not_empty(cellData) ? cellData : ""; // Isi nilai sel
      td.style.fontSize = style.fontSize ? `${style.fontSize}px` : "";
      td.style.fontFamily = style.fontName || "";
      td.style.fontWeight = style.bold ? "bold" : "";
      td.style.fontStyle = style.italic ? "italic" : "";
      td.style.textDecoration = style.underline ? "underline" : "";

      if (style?.fillColor?.[0] === "#") {
        td.style.color = style.fontColor;
      }

      if (style?.fillColor?.[0] === "#") {
        td.style.backgroundColor = style?.fillColor;
      }

      if (style.border) {
        td.style.borderTop = style.border.top
          ? `${style.border.top.width || 1}px ${
              style.border.top.style || "solid"
            } ${style.border.top.color || "#000"}`
          : "";
        td.style.borderRight = style.border.right
          ? `${style.border.right.width || 1}px ${
              style.border.right.style || "solid"
            } ${style.border.right.color || "#000"}`
          : "";
        td.style.borderBottom = style.border.bottom
          ? `${style.border.bottom.width || 1}px ${
              style.border.bottom.style || "solid"
            } ${style.border.bottom.color || "#000"}`
          : "";
        td.style.borderLeft = style.border.left
          ? `${style.border.left.width || 1}px ${
              style.border.left.style || "solid"
            } ${style.border.left.color || "#000"}`
          : "";
      }
    };
  }

  return cellProperties;
};
