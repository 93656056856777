/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX*/
import { searchUser } from "../../App/actions/groupActions";
import {
  pushMemberThen,
  pushGroup,
  deleteMember,
  editMember,
  deleteGroup,
} from "../../App/actions/projectActions";
import { set_value_user, resetSearchUser } from "../../App/actions/authActions";

/*PICTURE*/
import pic_static from "../../Assets/svg/profile_pic_comment.svg";
import checklist from "../../Assets/svg/checklist.svg";
import icon_search from "../../Assets/svg/icon_search.svg";
import icon_delete_2 from "../../Assets/svg/icon_delete_2.svg";

/*FUNCTION*/
import isEqual from "../../App/validation/is-equal";
import uuid from "../../App/validation/uuid";
import format_date_formal from "../../App/validation/format-date";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class PROJECT_COLLAB extends Component {
  state = {
    geo_project: {},
    user_id: this.props.auth.user._id,
    search_user: "",
    member_id: "",
    group: {},
    member: {},
    modal_add_group: false,
    modal_warning: false,
    modal_delete_group: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.project.geo_project, prevState.geo_project)) {
      return {
        geo_project: nextProps.project.geo_project,
        group:
          nextProps.project.geo_project.groups?.length > 0
            ? nextProps.project.geo_project.groups[0]
            : {},
      };
    } else return null;
  }

  componentDidMount() {
    this.timer = null;
  }

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  toggle_add_group = () => {
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = ![
      // "license_academy",
      // "license_1",
      "license_2",
      "license_3",
    ].includes(payment_type);
    const logic_2 = !license_group_status?.is_has_license;
    if (logic_1 && logic_2) {
      this.toggle_payment_universal();
    } else {
      this.setState({
        modal_add_group: !this.state.modal_add_group,
      });
    }
  };

  toggle_delete_group = (group) => {
    this.setState(
      { modal_delete_group: !this.state.modal_delete_group },
      () => {
        if (!!group?._id) {
          this.setState({ group });
        }
      }
    );
  };

  toggle_warning = () => {
    this.setState({ modal_warning: !this.state.modal_warning });
  };

  setGroup = (group) => {
    this.setState({ group });
  };

  on_submit = (group_id) => {
    const { geo_project } = this.state;
    const { folder_id_selected } = this.props.project;
    let key;
    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }
    const body = {
      key,
      geo_project_id: geo_project._id,
      group_id,
    };
    this.props.pushGroup(body);
    this.setState({ modal_add_group: false });
  };

  on_delete = () => {
    this.setState({ modal_delete_group: false });
    const { geo_project, group } = this.state;
    const { folder_id_selected } = this.props.project;
    let key;
    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }
    const body = {
      key,
      geo_project_id: geo_project._id,
      group_id: group._id,
    };
    this.props.deleteGroup(body);
  };

  on_change_search_user(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.trigger_change.bind(this), WAIT_INTERVAL);
  }

  on_key_down(e) {
    if (e.keyCode === ENTER_KEY) {
      this.trigger_change.bind(this);
    }
  }

  trigger_change() {
    const { search_user } = this.state;
    this.props.searchUser(search_user);
  }

  on_reset_search = () => {
    this.setState({ search_user: "" }, () => {
      this.props.resetSearchUser();
    });
  };

  on_push_member = (member_id) => {
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = ![
      // "license_academy",
      // "license_1",
      "license_2",
      "license_3",
    ].includes(payment_type);
    const logic_2 = !license_group_status?.is_has_license;
    if (logic_1 && logic_2) {
      this.toggle_payment_universal();
    } else {
      this.setState({ member_id }, () => {
        const { geo_project, member_id, group } = this.state;
        const { folder_id_selected } = this.props.project;
        let key;
        if (this.props.title === "Pinned") {
          key = "geo_project_pinned_list";
        } else if (!!folder_id_selected) {
          key = "geo_project_list_child";
        } else {
          key = "geo_project_list";
        }
        const body = {
          key,
          geo_project_id: geo_project._id,
          group_id: group._id,
          member_id: member_id,
        };
        this.props.pushMemberThen(body);
      });
    }
  };

  toggle_delete_member = (member) => {
    this.setState({ member }, () => {
      this.setState({
        modal_delete_member: !this.state.modal_delete_member,
      });
    });
  };

  on_delete_member = () => {
    const { geo_project, group, member } = this.state;
    const { _id } = geo_project;
    const { folder_id_selected } = this.props.project;
    let key;
    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }
    const body = {
      key,
      geo_project_id: _id,
      group_id: group?._id,
      user_id: member?._id,
      user_id_real: member?.user?._id,
      member_name: member?.user?.full_name
        ? member?.user?.full_name
        : member?.user?.name,
    };
    this.props.deleteMember(body);
    this.toggle_delete_member(this.state.member);
  };

  on_edit_member = (e) => {
    e.preventDefault();
    const { geo_project, group } = this.state;
    const { folder_id_selected } = this.props.project;
    let key;
    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }
    const status = e.target.value;
    const user_id = e.target.name;
    const user_id_real = e.target.id;
    const body = {
      key,
      user_id,
      status,
      geo_project_id: geo_project._id,
      group_id: group._id,
      user_id_real,
    };
    this.props.editMember(body);
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      geo_project,
      search_user,
      modal_add_group,
      modal_warning,
      modal_delete_group,
      group,
    } = this.state;

    //global props
    const { user_list, user, groups } = this.props.auth;

    //content
    let groups_id = [];
    if (geo_project?.groups?.length > 0) {
      const groups = geo_project.groups;
      groups_id = groups.map(({ _id }) => {
        return _id;
      });
    }

    let member_status_current = "viewer";
    let group_members = [];
    if (group?._id) {
      group_members = group.members;
      const array_member_filtered = group.members.filter(
        (member) => member?.user?._id === user?._id
      )[0];
      if (array_member_filtered?.status) {
        member_status_current = array_member_filtered?.status;
      } else {
        member_status_current = "viewer";
      }
    }

    const group_list_content = geo_project.groups.map((group_element, idx) => {
      const { name, _id, members } = group_element;
      let userCurrentStatus = "viewer";
      const array_member_filtered = members.filter(
        (member) => member?.user?._id === user?._id
      )[0];
      userCurrentStatus = array_member_filtered?.status;
      let button_delete;
      if (userCurrentStatus === "creator" || userCurrentStatus === "admin") {
        button_delete = (
          <div
            onClick={this.toggle_delete_group.bind(this, group_element)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={icon_delete_2}
              alt="delete group"
              width="15px"
              height="auto"
            />
          </div>
        );
      }
      const is_active = group?._id === _id;
      const container_class = `container_small cursor_pointer margin_right inline background_grey_light ${
        is_active ? "outline_black" : "outline_grey"
      }`;
      const item = (
        <div
          className={container_class}
          onClick={this.setGroup.bind(this, group_element)}
          key={idx}
        >
          <p
            className="text_small"
            style={{ overflow: "hidden", maxHeight: "30px" }}
          >
            {name}
          </p>
          {button_delete}
        </div>
      );
      return item;
    });

    const add_group_button = (
      <div
        className="container_small background_grey_light outline_grey inside_center_big"
        onClick={this.toggle_add_group}
        data-mapid="addGroup"
      >
        +
      </div>
    );

    const modal_add_group_content = modal_add_group && (
      <Modal
        modalSize="full"
        is_hide="show"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        blur_value="5px"
        borderRadius="5px"
        isOpen={this.state.modal_add_group}
        onClose={this.toggle_add_group}
      >
        <div
          className="box-body"
          style={{ textAlign: "center", minHeight: "80vh" }}
        >
          <div style={{ marginBottom: "30px" }}>
            {dict["Add Group into Project"][language]}
          </div>
          <div>
            {groups.map(({ name, _id, members, payment }, idx) => {
              let org_content = null;
              if (payment?.date_in) {
                const { date_in } = payment;
                const multiple_month = payment.multiple_month
                  ? payment.multiple_month
                  : 1;
                //86400 unix = 1 day
                //1 month = 30.5 day
                const month_unix = 86400 * 30.5;
                const day_unix = 86400;
                const date_finish_unix =
                  parseInt((new Date(date_in).getTime() / 1000).toFixed(0)) +
                  month_unix * multiple_month;
                const date_now_unix = parseInt(
                  (new Date(Date.now()).getTime() / 1000).toFixed(0)
                );
                const delta_unix =
                  (date_finish_unix - date_now_unix) * multiple_month;
                const delta_day = parseInt(delta_unix / day_unix);
                const date_finish = new Date(date_finish_unix * 1000);
                if (delta_day > 0) {
                  if (delta_day > 30) {
                    org_content = (
                      <div className="button_small background_white">
                        <b>
                          {dict["Team license active until"][language]}{" "}
                          {format_date_formal(date_finish)}
                        </b>
                      </div>
                    );
                  } else {
                    org_content = (
                      <div className="button_small background_white">
                        <b>
                          {dict["Team license active for"][language]}{" "}
                          {delta_day} {dict["days"][language]}
                        </b>
                      </div>
                    );
                  }
                } else {
                  org_content = (
                    <div className="button_small background_white">
                      <b>{dict["Team license exp."][language]}</b>
                    </div>
                  );
                }
              }
              let member_status = "viewer";
              const array_member_filtered = members.filter(
                (member) => member?.user?._id === user?._id
              )[0];
              if (array_member_filtered) {
                member_status = array_member_filtered?.status;
              }
              let groupCard;
              if (groups_id.includes(_id)) {
                groupCard = (
                  <div
                    className="container_small background_grey_light margin_right margin_bottom outline_black"
                    key={idx}
                  >
                    <div className="text_medium margin_bottom">
                      {name}{" "}
                      <img alt="checklist" src={checklist} width="11px" />
                    </div>
                    {org_content}
                  </div>
                );
              } else {
                if (member_status === "creator" || member_status === "admin") {
                  groupCard = (
                    <div
                      className="container_small background_blue margin_right margin_bottom outline_black"
                      onClick={this.on_submit.bind(this, _id)}
                      key={idx}
                      data-mapid="addGroupAdmin"
                    >
                      <div className="text_medium margin_bottom">{name}</div>
                      {org_content}
                    </div>
                  );
                } else {
                  groupCard = (
                    <div
                      className="container_small background_grey_light margin_right margin_bottom outline_black"
                      onClick={this.toggle_warning}
                      key={idx}
                    >
                      <div className="text_medium margin_bottom">{name}</div>
                      {org_content}
                    </div>
                  );
                }
              }
              return groupCard;
            })}
          </div>
        </div>
      </Modal>
    );

    let members_group_id = [];
    if (geo_project.groups.length > 0) {
      const members_group = group.members;
      members_group_id = members_group.map((member) => member?.user?._id);
    }

    const modal_delete_member_content = this.state.modal_delete_member && (
      <Modal
        modalSize="small"
        id="deleteModal"
        isOpen={this.state.modal_delete_member}
        onClose={this.toggle_delete_member.bind(this, this.state.member)}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          Are you sure to delete
          <div className="welcome" style={{ textAlign: "center" }}>
            {this.state.member?.user?.full_name
              ? this.state.member?.user?.full_name
              : this.state.member?.user?.name}
          </div>
          <br />
          <div
            className="button background_blue"
            id="deleteBright"
            style={{ marginTop: "1rem" }}
            onClick={this.on_delete_member}
            data-mapid="clickDelete"
          >
            {dict["Process deletion"][language]}
          </div>
        </div>
      </Modal>
    );

    const modal_warning_content = modal_warning && (
      <Modal
        modalSize="small"
        id="warning modal"
        isOpen={modal_warning}
        onClose={this.toggle_warning}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <div className="text_bold">
            {
              dict[
                "You are not an admin in this group, only admins can add groups to the project."
              ][language]
            }
          </div>
        </div>
      </Modal>
    );

    const modal_delete_group_content = modal_delete_group && (
      <Modal
        modalSize="small"
        id="deleteModal"
        isOpen={modal_delete_group}
        onClose={this.toggle_delete_group}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <div className="text_medium">
            {dict["Are you sure to delete"][language]} <b>{group.name}</b>{" "}
            {dict["from"][language]} <b>{geo_project.name}</b>?
          </div>
          <br />
          <div className="text_inferior">
            {
              dict[
                "This action will not delete the group, just remove the group association with this project, the members of this group will no longer have access to this project."
              ][language]
            }
          </div>
          <br />
          <div
            className="button background_blue"
            id="deleteBright"
            style={{ marginTop: "1rem" }}
            onClick={this.on_delete}
          >
            {dict["Process deletion"][language]}
          </div>
        </div>
      </Modal>
    );

    return (
      <div className="max_height">
        {modal_delete_member_content}
        {modal_add_group_content}
        {modal_warning_content}
        {modal_delete_group_content}
        <div style={{ textAlign: "center" }}>
          <div className="carousell">
            {group_list_content}
            {(member_status_current === "creator" ||
              member_status_current === "admin" ||
              user?._id === geo_project?.user?._id) &&
              add_group_button}
          </div>
          {(member_status_current === "creator" ||
            member_status_current === "admin") &&
            group?._id && (
              <main
                className="input_rounded margin_top"
                style={{ width: "100%", boxSizing: "border-box" }}
              >
                <main className="icon-text">
                  <section>
                    <img
                      src={icon_search}
                      alt="search"
                      height="16px"
                      className="margin_right"
                    />
                  </section>
                  <section>
                    <input
                      className="form-rounded"
                      type="text"
                      placeholder={`${dict["Search user to add as member"][language]}...`}
                      autoComplete="off"
                      name="search_user"
                      value={search_user}
                      onChange={this.on_change_search_user.bind(this)}
                      onKeyDown={this.on_key_down.bind(this)}
                      data-mapid="inputUser"
                    />
                  </section>
                  <section>
                    <div
                      onClick={this.on_reset_search}
                      style={{ marginBottom: "5px", cursor: "pointer" }}
                    >
                      X
                    </div>
                  </section>
                </main>
                <main>
                  <main
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                    data-mapid="clickUser"
                  >
                    {user_list.map(
                      ({ _id, name, full_name, profile_picture }, idx) => {
                        const isMember = members_group_id.includes(_id);
                        const displayName =
                          full_name && full_name !== "" && full_name !== "-"
                            ? full_name
                            : name;
                        const profilePic =
                          profile_picture && profile_picture.url_compressed
                            ? profile_picture.url_compressed
                            : profile_picture &&
                              profile_picture.url &&
                              ![
                                "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png",
                                "-",
                                "default",
                              ].includes(profile_picture.url)
                            ? profile_picture.url
                            : pic_static;
                        return (
                          <main
                            key={idx}
                            className={`user-unit ${
                              isMember ? "member" : "non-member"
                            }`}
                            onClick={
                              !isMember
                                ? this.on_push_member.bind(this, _id)
                                : undefined
                            }
                          >
                            <section
                              alt={_id}
                              className="user-photo"
                              style={{ backgroundImage: `url(${profilePic})` }}
                            />
                            <section className="user-name">
                              {displayName}
                            </section>
                            {isMember && (
                              <section
                                alt={_id}
                                className="badge-member"
                                style={{ backgroundImage: `url(${checklist})` }}
                              />
                            )}
                          </main>
                        );
                      }
                    )}
                  </main>
                </main>
              </main>
            )}
          <main style={{ marginTop: "20px" }}>
            <p>{dict["Members in this project group"][language]}:</p>
            <table>
              <tbody>
                {group_members.map((member, idx) => {
                  const user = member?.user;
                  const status = member?.status;
                  let _id = uuid();
                  let member_id = uuid();
                  let full_name, profile_picture, name;
                  if (user) {
                    member_id = member._id;
                    _id = user?._id;
                    full_name = user?.full_name;
                    profile_picture = user?.profile_picture;
                    name = user?.name;
                  } else {
                    full_name = "Deleted user";
                  }
                  let deleteButton;
                  const statusArray = ["admin", "contributor", "viewer"];
                  let statusButton;

                  if (status === "creator") {
                    statusButton = <p>{member?.status}</p>;
                  } else if (
                    member_status_current === "creator" ||
                    (member_status_current === "admin" &&
                      member?.user?.name !== this.props.auth.user?.name)
                  ) {
                    statusButton = (
                      <select
                        id={_id}
                        name={member_id}
                        value={status}
                        onChange={this.on_edit_member}
                        data-mapid={`selectStatus-${idx}`}
                      >
                        {statusArray.map((status, idx) => {
                          return (
                            <option key={idx} value={status}>
                              {status}
                            </option>
                          );
                        })}
                      </select>
                    );
                  } else {
                    statusButton = <div>{status}</div>;
                  }
                  if (
                    (member_status_current === "creator" ||
                      member_status_current === "admin") &&
                    status !== "creator" &&
                    member?.user?.name !== this.props.auth.user?.name
                  ) {
                    deleteButton = (
                      <div
                        onClick={this.toggle_delete_member.bind(this, member)}
                        className="button background_blue"
                        id="red"
                        data-mapid="deleteUser"
                      >
                        {dict["Delete"][language]}
                      </div>
                    );
                  } else {
                    deleteButton = null;
                  }
                  const picture_src =
                    profile_picture && profile_picture.url_compressed
                      ? profile_picture.url_compressed
                      : profile_picture &&
                        profile_picture.url &&
                        profile_picture.url !==
                          "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                        profile_picture.url !== "-" &&
                        profile_picture.url !== "default"
                      ? profile_picture.url
                      : pic_static;
                  return (
                    user && (
                      <tr key={idx} data-mapid={`tableTr-${idx}`}>
                        <td
                          style={{
                            textAlign: "left",
                            marginBottom: "10px",
                            width: "40px",
                          }}
                        >
                          <div
                            alt={_id}
                            className="user-photo"
                            style={{ backgroundImage: `url(${picture_src})` }}
                            samesite="None"
                            secure="true"
                          />
                        </td>
                        <td
                          style={{
                            width: "250px",
                          }}
                        >
                          <div style={{ textAlign: "left" }}>
                            {full_name && full_name !== "" && full_name !== "-"
                              ? full_name
                              : name}
                          </div>
                        </td>
                        <td
                          style={{
                            width: "150px",
                          }}
                        >
                          <div style={{ textAlign: "left" }}>
                            {member_status_current !== "creator" &&
                            member_status_current !== "admin" ? (
                              <p>{member?.status}</p>
                            ) : (
                              statusButton
                            )}
                          </div>
                        </td>
                        <td
                          style={{
                            width: "100px",
                          }}
                        >
                          {deleteButton}
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  pushMemberThen,
  searchUser,
  editMember,
  deleteMember,
  resetSearchUser,
  pushGroup,
  deleteGroup,
  set_value_user,
})(PROJECT_COLLAB);
