import React, { Component } from "react";
import { HotTable } from "@handsontable/react";
import { convertExcelDataToHandsontableFormat, setStyleHandsonTable } from "../../App/validation/convert_data";

class HotTableWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    // Hanya render ulang jika worksheet berubah
    return (
      nextProps.worksheet !== this.props.worksheet ||
      nextProps.selectedSheet !== this.props.selectedSheet
    );
  }

  handleBeginEditing = (row, column) => {
    const { textfield, hotTableRef } = this.props;
    const hotInstance = hotTableRef?.current?.hotInstance;
    const editor = hotInstance?.getActiveEditor();

    if (editor) {
      editor.setValue(textfield); // Set initial value directly in the editor
    }
  };

  render() {

    const {
      worksheet,
      handle_selected_cell,
      setFocused,
      handle_edit_cell,
      hotTableRef,
      isDeleting,
      selectedSheet
    } = this.props;

    const sheet =
      worksheet?.find((obj) => obj?.metadata?.id_ori === selectedSheet) || {};

    const { data, columns } = convertExcelDataToHandsontableFormat({
      sheet,
      selectedSheet,
      rawData: worksheet,
    });



    const is_mobile = window.innerWidth < 600;

    return (
      <HotTable
        style={{
          border: "1px solid #AAA",
          borderRadius: "4px",
        }}
        cells={(row, col) =>
          setStyleHandsonTable({
            row,
            col,
            data,
            sheet,
            worksheet,
            selectedSheet,
          })
        }
        ref={hotTableRef}
        width="100%"
        data={data}
        columns={columns}
        header
        rowHeaders={true}
        colHeaders={true}
        height={((is_mobile ? 75 : 76) / 100) * window.innerHeight}
        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
        settings={{
          minSpareRows: 5,
          minSpareCols: 5, // Tambahkan baris kosong di akhir
          contextMenu: true, // Menampilkan opsi konteks (cut, copy, paste)
          manualColumnResize: true,
          manualRowResize: true,
          // beforeKeyDown: this.handleBeforeKeyDown,
        }}
        selectionMode="multiple"
        afterSelectionEnd={(startRow, startCol, endRow, endCol) => {
          handle_selected_cell({
            startRow,
            startCol,
            endRow,
            endCol,
          });
        }}
        // afterBeginEditing={(row, column) => {
        //   const hotInstance = hotTableRef?.current?.hotInstance;
        //   const editor = hotInstance.getActiveEditor();
        //   if (editor) {
        //     editor.setValue(textfield); // Set initial value directly in the editor
        //   }
        // }}

        afterBeginEditing={this.handleBeginEditing}
        afterChange={(changed_data, event) => {
          if (isDeleting) {
            // Abaikan perubahan jika sedang menghapus
            return;
          }

          if (event === "edit") {
            handle_edit_cell(changed_data);
          }
        }}
        afterDeselect={(e) => {
          setFocused(null);
        }}
        outsideClickDeselects={(e) => {
          return false;
        }}
        modifyColWidth={(width) => {
          if (width > 300) {
            return 300;
          }
        }}
      />
    );
  }
}

export default HotTableWrapper;